import { Route, Routes } from 'react-router-dom'
import { Registration } from './components/Registration'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { AuthLayout } from './AuthLayout'
import SMSNew from '../../pages/sms-new/sms-new'

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route index element={<SMSNew />} />
      {/* <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} /> */}
    </Route>
  </Routes>
)

export { AuthPage }
