import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { axiosClientAuth } from '../../utils'
import Swal from 'sweetalert2'

const initialValues = {
  campaign: '245',
  coverage: '+91',
  routes: '92',
  senderid: 'SANJUP',
  pe_id: '1501550540000010698',
  number: '9104210103',
  content_id: '1507167577648640421',
  message:
    'Thank you for showing interest, for more information please click on below link {#var#} Sanjay',
}

const validationSchema = Yup.object().shape({
  campaign: Yup.string().required(),
  coverage: Yup.string().required(),
  routes: Yup.string().required(),
  senderid: Yup.string().required(),
  pe_id: Yup.string().required(),
  number: Yup.string().required(),
  content_id: Yup.string().required(),
  message: Yup.string().required(),
})

const SMSNew = () => {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      let phoneNumbers = values.number.split(",");
      for (let i = 0; i < phoneNumbers.length; i++) {
        let mNumber = phoneNumbers[i].trim();
        try {
          const res1 = await fetch(
            `https://smartping-backend.goflipo.com/api/main/scrubbing-logs?campaign=${values.campaign}&coverage=${values.coverage}&routes=${values.routes}&senderid=${values.senderid}&pe_id=${values.pe_id}&number=${mNumber}&content_id=${values.content_id}&message=${values.message}`
          )
          const data1 = await res1.json()
          console.log(data1)
          const res = await fetch('https://tm2.goflipo.com/api/api/send_sms/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...values, number: mNumber, authcode: data1.data.authcode }),
          })
          const data = await res.json()
          setLoading(false)

          if (res.status == 200) {
            Swal.fire({
              titleText: 'SMS Sent Successfully!',
              // text: `DLR code - ${data2.data.dlr_code}`,
              icon: 'success',
              confirmButtonText: 'Ok! Got It',
            }).then((isConfirm) => {
              if (isConfirm) {
                if (i == phoneNumbers.length - 1)
                  window.location.reload()
              }
            })
          }

          if (res.status == 400) {
            Swal.fire({
              titleText: 'Error',
              text: `${data.message}`,
              icon: 'error',
              confirmButtonText: 'Ok',
            }).then((isConfirm) => {
              if (isConfirm) {
                // window.location.reload()
              }
            })
          }
        } catch (error: any) {
          console.log(error)
          Swal.fire({
            titleText: 'Error',
            text: `${error.message}`,
            icon: 'error',
            confirmButtonText: 'Ok',
          }).then((isConfirm) => {
            if (isConfirm) {
              // window.location.reload()
            }
          })
        }
      }
    },
  })

  return (
    <>
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        {/* <div className='text-center mb-11'>
          <img src='/media/logos/transparency-dark.png' className='w-25' />
        </div> */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : null}

        <div className='row mb-8'>
          <div className='col-6 mb-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>Campaign</label>
            <select
              className='form-select form-select-sm form-select-solid'
              {...formik.getFieldProps('campaign')}
            >
              <option value='1'>Camp1</option>
              <option value='50'>Test camp</option>
              <option value='59'>bvdd</option>
              <option value='245'>Default</option>
              <option value='294'>For Testing</option>
              <option value='298'>Demo-camp</option>
              <option value='299'>Test Campaigns</option>
              <option value='421'>Test Campaigns</option>
              <option value='423'>discount promo</option>
              <option value='451'>Testing_abhi</option>
            </select>
            {formik.touched.campaign && formik.errors.campaign && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.campaign)}</span>
              </div>
            )}
          </div>

          <div className='col-6 mb-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>Coverage</label>
            <select
              className='form-select form-select-sm form-select-solid'
              {...formik.getFieldProps('coverage')}
            >
              <option value=''>Select Coverge</option>
              <option value='+91'>(+91) India</option>
            </select>
            {formik.touched.coverage && formik.errors.coverage && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.coverage)}</span>
              </div>
            )}
          </div>

          <div className='col-6 mb-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>Routes</label>
            <select
              className='form-select form-select-sm form-select-solid'
              {...formik.getFieldProps('routes')}
            >
              <option value=''>Select Route</option>
              <option value='92'>live_trans</option>
              <option value='102'>UNI_INT_NEW</option>
              <option value='98'>unitest1</option>
              <option value='108'>UniTest VCON</option>
              <option value='112'>transpTr</option>
              <option value='139'>BMGTest</option>
            </select>
            {formik.touched.routes && formik.errors.routes && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.routes)}</span>
              </div>
            )}
          </div>

          <div className='col-6 mb-4'>
            <label className='form-label fs-6 fw-bolder text-dark required'>SenderID</label>
            <select
              className='form-select form-select-sm form-select-solid'
              {...formik.getFieldProps('senderid')}
            >
              <option value=''>Select Sender ID</option>
              <option value='ABCDEFGHI'>ABCDEFGHI</option>
              <option value='BulkAr'>BulkAr</option>
              <option value='SANJUP'>SANJUP</option>
            </select>
            {formik.touched.senderid && formik.errors.senderid && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.senderid)}</span>
              </div>
            )}
          </div>

          <div className='col-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Principal Entity id</label>
            <input
              placeholder='Entity Id'
              {...formik.getFieldProps('pe_id')}
              className={clsx(
                'form-control bg-transparent mb-5',
                { 'is-invalid': formik.touched.pe_id && formik.errors.pe_id },
                {
                  'is-valid': formik.touched.pe_id && !formik.errors.pe_id,
                }
              )}
              type='text'
              name='pe_id'
              autoComplete='off'
            />
            {formik.touched.pe_id && formik.errors.pe_id && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.pe_id)}</span>
              </div>
            )}
          </div>

          <div className='col-6'>
            <label className='form-label fs-6 fw-bolder text-dark'>Content ID</label>
            <input
              placeholder='Contact Id'
              {...formik.getFieldProps('content_id')}
              className={clsx(
                'form-control bg-transparent mb-5',
                { 'is-invalid': formik.touched.content_id && formik.errors.content_id },
                {
                  'is-valid': formik.touched.content_id && !formik.errors.content_id,
                }
              )}
              type='text'
              name='content_id'
              autoComplete='off'
            />
            {formik.touched.content_id && formik.errors.content_id && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.content_id)}</span>
              </div>
            )}
          </div>

          <div className='col-12'>
            <label className='form-label fs-6 fw-bolder text-dark'>Contact No</label>
            <input
              placeholder='Comma Separated contact number'
              {...formik.getFieldProps('number')}
              className={clsx(
                'form-control bg-transparent mb-5',
                { 'is-invalid': formik.touched.number && formik.errors.number },
                {
                  'is-valid': formik.touched.number && !formik.errors.number,
                }
              )}
              type='text'
              name='number'
              autoComplete='off'
            />
            {formik.touched.number && formik.errors.number && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.number)}</span>
              </div>
            )}
          </div>

          <div className='col-12'>
            <label className='form-label fs-6 fw-bolder text-dark'>Message</label>
            <textarea
              placeholder='Message'
              {...formik.getFieldProps('message')}
              className={clsx(
                'form-control bg-transparent mb-5',
                { 'is-invalid': formik.touched.message && formik.errors.message },
                {
                  'is-valid': formik.touched.message && !formik.errors.message,
                }
              )}
              name='message'
              autoComplete='off'
            />
            {formik.touched.message && formik.errors.message && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{JSON.stringify(formik.errors.message)}</span>
              </div>
            )}
          </div>

          <div className='col-12 text-center mt-4'>
            {/* begin::Action */}
            <div className='mb-10'>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>Send SMS</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </div>
        </div>

        <div className='text-gray-500 text-center fw-semibold fs-6'>
          Need any help ? {/* <Link to='#' className='link-primary'> */}
          Contact Us
          {/* </Link> */}
        </div>
      </form>
    </>
  )
}

export default SMSNew
